<template>
  <v-container fluid class="mt-0 pt-0">
    <v-layout row wrap>
      <card-container class="ma-0 pa-0">
        <v-flex slot="left" lg12>
          <div>
            <h1 class="textOrange--text display-1">
              Time to Review your Orientation BeeZCard
            </h1>
          </div>

          <div class="content-text body-1 font-weight-light mt-12">
            An <span class="textOrange--text">Orientation card</span> is A Digital Card That Contains All The List Of
            Tasks You Should Do Before Joining <span class="textOrange--text">your company</span>. This Feature Allows
            You To Access All The Relevant Information In Order To Make The
            Process As Simple And Efficient As Possible.
          </div>
        </v-flex>

        <v-flex slot="right" xs12 sm12 md12 lg12>
          <v-card
            align="left"
            class="intro-card ma-0 pa-0"
            outlined
            width="319"
          >
            <v-card-text class="primary textLight--text body-2"
              >Orientation Card</v-card-text
            >
            <div v-if="descriptions.length == 0">
              <v-card
                flat
                class="pa-4 pt-12 mt-12 ma-8 d-flex align-center justify-center textDark--text"
              >
                <v-card-text class="d-flex justify-center mt-8"
                  >No Tasks to be previewed</v-card-text
                >
              </v-card>
            </div>
            <div else>
               
              
              <BzTags
                class="py-6 px-3"
                darkColor="primary"
                lightColor="lightBlue"
                textColor="primary--text"
                :hasCheckbox="true"
                :listOfTags="descriptions"
                v-on:onItemClicked="onItemClicked($event)"
              />
              <v-card v-if="descriptions.length<5"
                flat
                class="pa-4 ma-6 d-flex align-center justify-center textDark--text"
              >
                <v-card-text class="d-flex justify-center "
                  ></v-card-text
                >
              </v-card>
            </div>
          </v-card>
        </v-flex>
      </card-container>
    </v-layout>
    <bz-footer
      text="Click on Continue Button after Reviewing BeeZCard"
      buttonTitle="Continue"
      @click="display=true"
    ></bz-footer>
    <congratulations
      :display="display"
      continueTo="user-profile"
      type="onboarding"
    ></congratulations>
  </v-container>
</template>
<script>
import BzTags from "@/shared/components/BzTags.vue";
import CardContainer from "../../../components/CardContainer";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import Congratulations from "../../../components/Congratulations.vue";
export default {
  data() {
    return {
      display: false,
      descriptions: [],
      cardId: null,
      loading: false,
      pageRender: false,
      selected: [],
      tasks: [],
      allTasks: [],
      currentAccountId: "",
      new: false
    };
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("orientation", ["orientationCardData"])
  },
  components: {
    BzTags,
    CardContainer,
    Congratulations
  },
  async created() {
    this.currentAccountId = this.currentUser.roles[0].accountId;
    await this.getTheTasks();
  },
  methods: {
    ...mapActions("orientation", {
      getOrientationTasks: "getOrientationTasks"
    }),
    onItemClicked: function(selected) {
      this.selected = selected;
    },
    async getTheTasks() {
      try {
        await this.getOrientationTasks({
          accountId: this.currentAccountId
        });
        this.allTasks = JSON.parse(JSON.stringify(this.orientationCardData));
        if (this.allTasks.length == 0) {
          this.new = true;
          this.pageRender = true;
          return;
        }
        let j = 0;
        // this.id = this.allTasks[0].tasks[0].description;
        this.cardId = this.allTasks[0]._id;
        let orientationTask = this.allTasks[0].tasks;
        for (var t in orientationTask) {
          let { description } = orientationTask[t];

          this.descriptions.push(description);
          console.log("oc", t);
        }

        this.pageRender = true;
      } catch (error) {
        if (error.name === "NotFound") {
          this.descriptions = [];
          this.pageRender = true;
        }
      }
    }
  }
};
</script>
<style scoped>
.intro-card {
  box-shadow: 0px 0px 99px #00000029;
}
</style>
